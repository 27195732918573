<template>
  <Page
    has-actions
    :subtitle="`${items.length} ${$tc('result', items.length <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <Card content-class="c-table">
      <div class="columns is-mobile is-multiline is-variable is-5 m-0">
        <div
          class="column is-9-tablet is-12-mobile p-0 pb-3 column-separator-right"
        >
          <b-table
            :loading="loading"
            :data="items"
            :total="items.length"
            :per-page="pagination.perPage"
            :current-page="pagination.page"
            paginated
            hoverable
          >
            <b-table-column
              v-slot="props"
              field="id"
              :label="this.$i18n.t('id')"
              header-class="sticky-header"
            >
              <a
                :id="props.row.id"
                href="javascript:;"
                class="is-clickable"
                @click="openEditTag(props.row)"
              >
                {{ props.row.id }}
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="tag"
              :label="this.$i18n.tc('tags', 1)"
              header-class="sticky-header"
            >
              <b-tag
                :style="{
                  background: props.row.background,
                  color: props.row.color,
                  border: '1px solid' + props.row.border,
                }"
                >{{ props.row.tag }}</b-tag
              >
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="type"
              :label="this.$i18n.t('type')"
              header-class="sticky-header"
            >
              {{ props.row.type }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="count"
              :label="this.$i18n.t('count')"
              header-class="sticky-header"
            >
              {{ props.row.count || "-" }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="active"
              :label="$tc('active', 1)"
              header-class="sticky-header"
              sortable
              centered
            >
              <b-icon
                :icon="props.row.active ? 'check' : 'close'"
                :type="props.row.active ? 'is-success' : 'is-danger'"
                size="is-small"
              />
            </b-table-column>
          </b-table>
          <Pagination
            :per-page="pagination.perPage"
            :per-pages="rowsPerPage"
            :total="items.length"
            :current-page="pagination.page"
            @update-per-page="(val) => (pagination.perPage = val)"
            @update-current-page="(val) => (pagination.page = val)"
          />
        </div>
        <div
          id="form"
          class="column is-3-tablet is-12-mobile pt-4 column-separator-right"
          :class="{ 'px-0': $device.mobile }"
        >
          <div class="sticky">
            <p
              class="is-size-5 pb-5 is-flex is-justify-content-space-between is-align-items-baseline"
            >
              <b>{{
                editTag.id
                  ? form && form.titles && form.titles.update
                  : form && form.titles && form.titles.create
              }}</b>
              <template v-if="editTag.id">
                <span
                  v-if="editTag.actions.delete"
                  class="is-clickable is-size-7"
                  @click="deleteTag"
                >
                  <b-icon icon="delete" size="is-small" type="is-danger" />
                  {{ editTag.actions.delete.label }}
                </span>
                <span
                  v-else-if="editTag.actions.restore"
                  class="is-clickable is-size-7"
                  @click="restoreTag"
                >
                  <b-icon icon="refresh" size="is-small" type="is-success" />
                  {{ editTag.actions.restore.label }}
                </span>
              </template>
            </p>
            <ValidationObserver
              ref="observer"
              v-slot="{ handleSubmit }"
              tag="div"
            >
              <form
                v-if="form && form.fields"
                method="post"
                autocomplete="off"
                @submit.prevent="handleSubmit(saveTag)"
              >
                <b-input-validation
                  class="mb-3"
                  v-model="editTag.tag"
                  rules="required"
                  maxlength="25"
                  :label="form.fields.tag.label"
                  :disabled="form.fields.tag.disabled"
                  :placeholder="placeholders.carrier.name"
                />

                <b-select-validation
                  v-model="editTag.type"
                  rules="required"
                  :label="form.fields.type.label"
                  :disabled="form.fields.type.disabled"
                  :placeholder="$t('choose')"
                >
                  <template>
                    <option v-for="opt in types" :key="opt" :value="opt">
                      {{ opt }}
                    </option>
                  </template>
                </b-select-validation>

                <ul class="color-palette mt-1 mb-4">
                  <li
                    v-for="col in colors"
                    :key="col.background + col.border"
                    :class="
                      col.background === editTag.background &&
                      col.color === editTag.color
                        ? 'selected'
                        : ''
                    "
                    :style="{
                      background: col.background,
                      border: '1px solid' + col.border,
                    }"
                    @click="selectColor(col)"
                  ></li>
                </ul>
                <b-button
                  type="is-primary"
                  class="mr-3 mt-2"
                  :disabled="update_loading || form.actions.save.disabled"
                  :loading="update_loading"
                  tag="input"
                  native-type="submit"
                  :value="form.actions.save.label"
                >
                  {{ form.actions.save.label }}
                </b-button>
                <b-button
                  class="mt-2"
                  :disabled="update_loading || form.actions.cancel.disabled"
                  @click="resetEditTag()"
                >
                  {{ form.actions.cancel.label }}
                </b-button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </Card>
  </Page>
</template>
  
<script>
export default {
  data() {
    return {
      rowsPerPage: [15, 30],
      pagination: { perPage: 15, page: 1 },
      total: 0,
      //   page: 1,
      //   perPages: [2, 100, 200],
      //   perPage: 2,
      update_loading: false,
      items: [],
      colors: [],
      types: [],
      form: {},
      loading: true,
      searchFocused: false,
      search: "",
      editTag: {
        tag: "",
        background: "",
        color: "",
        border: "",
        type: null,
      },
    };
  },
  created() {
    this.updateTitle(this.$i18n.tc("tags", 2));
    this.getTags();
  },
  methods: {
    openEditTag(tag) {
      this.editTag = tag;
      // if (this.$device.mobile) {
      //   this.$scrollTo("#form");
      // }
    },
    resetEditTag() {
      this.editTag = {
        tag: "",
        background: this.colors[0].background,
        color: this.colors[0].color,
        border: this.colors[0].border,
        type: null,
        actions: null,
      };
      this.$refs.observer.reset();
    },
    selectColor(col) {
      this.editTag.background = col.background;
      this.editTag.color = col.color;
      this.editTag.border = col.border;
    },
    getTags() {
      this.loading = true;
      this.$axios
        .get(`/tags`)
        .then((res) => {
          this.items = res.data.list;
          this.form = res.data.form;
          this.colors = res.data.filters.colors;

          //select default colors
          this.editTag.background = this.colors[0].background;
          this.editTag.color = this.colors[0].color;
          this.editTag.border = this.colors[0].border;

          this.types = res.data.filters.types;
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
    deleteTag() {
      this.$axios
        .delete(this.editTag.actions.delete.url)
        .then(() => {
          this.editTag.active = false;
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch((e) => this.clientError(e));
      // .finally(() => ();
    },
    restoreTag() {
      this.$axios
        .patch(this.editTag.actions.restore.url)
        .then(() => {
          this.editTag.active = true;
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch((e) => this.clientError(e));
      // .finally(() => ());
    },
    saveTag() {
      this.update_loading = true;
      let url = "tags";
      let method = "POST";

      if (this.editTag.id) {
        url += `/${this.editTag.id}`;
        method = "PUT";
      }

      let formData = {
        tag: this.editTag.tag,
        type: this.editTag.type,
        background: this.editTag.background,
        color: this.editTag.color,
        border: this.editTag.border,
      };
      this.$axios({
        method: method,
        url: url,
        data: formData,
      })
        .then(({ data }) => {
          if (this.editTag.id) {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
            this.addItem(data);
          }
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.update_loading = false));
    },
    addItem(item) {
      this.items.unshift(item);
      this.resetEditTag();
    },
  },
};
</script>
  